export default function initCanvas() {
  /** *****************************************************************************
    Necessary Variables
  ****************************************************************************** */
  const wrapper = document.querySelector('#canvas-wrapper');
  if (!wrapper) return;

  const canvas = document.createElement('canvas');
  let isHovering = false;
  let cb = wrapper.getBoundingClientRect();
  const ctx = canvas.getContext('2d');
  const ratio = 1;
  const dots = [];
  const wide = 50;
  const high = 50;
  const size = 15;

  wrapper.appendChild(canvas);

  /** *****************************************************************************
    Rendering image
  ****************************************************************************** */
  const image = new Image();
  image.crossOrigin = 'Anonymous';
  image.src = '/statics/images/me.jpg';
  image.width = cb.width;
  image.height = cb.height;

  /** *****************************************************************************
    Utils
  ****************************************************************************** */
  function captureMouse(element) {
    const mouse = { x: 0, y: 0, event: null };
    const bodyScrollLeft = document.body.scrollLeft;
    const elementScrollLeft = document.documentElement.scrollLeft;
    const bodyScrollTop = document.body.scrollTop;
    const elementScrollTop = document.documentElement.scrollTop;
    const { offsetLeft } = element;
    const { offsetTop } = element;

    element.addEventListener('mousemove', (event) => {
      let x; let y;

      if (event.pageX || event.pageY) {
        x = event.pageX * ratio;
        y = event.pageY * ratio;
      } else {
        x = (event.clientX + bodyScrollLeft + elementScrollLeft) * ratio;
        y = (event.clientY + bodyScrollTop + elementScrollTop) * ratio;
      }
      x -= offsetLeft;
      y -= offsetTop;

      mouse.x = x;
      mouse.y = y;
      // mouse.event = event;
    }, false);

    return mouse;
  }
  /** *****************************************************************************
    Events
  ****************************************************************************** */
  let mouse = captureMouse(wrapper);

  wrapper.addEventListener('mouseover', () => isHovering = true);
  wrapper.addEventListener('mouseout', () => isHovering = false);

  window.onresize = function () {
    ctx.canvas.width = cb.width * ratio;
    ctx.canvas.height = cb.height * ratio;
    cb = wrapper.getBoundingClientRect();
    mouse = captureMouse(wrapper);
  };

  window.onresize();

  /** *****************************************************************************
    Setup
  ****************************************************************************** */
  function create() {
    for (let i = -1; ++i < wide;) {
      const x = Math.floor((cb.width / wide) * i) * ratio;

      for (let j = -1; ++j < high;) {
        const y = Math.floor((cb.height / high) * j) * ratio;

        dots.push({
          x,
          y,
        });
      }
    }
  }
  create();

  /** *****************************************************************************
    Rendering and frame calculations
  ****************************************************************************** */
  function render() {
    // clear the canvas
    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);

    // for each dot
    for (let i = 0; i < dots.length; i++) {
      const s = dots[i];

      const v = getV(s);

      ctx.drawImage(
        image,
        s.x + v.x,
        s.y + v.y,
        s.size * ratio,
        s.size * ratio,
        s.x + v.x + s.size,
        s.y + v.y + s.size,
        s.size * ratio,
        s.size * ratio,
      );

      ctx.fill();
    }
  }

  function getV(dot) {
    // find the distance from the line to the mouse
    const d = isHovering ? getDistance(dot, mouse) : 0;

    // reverse the distance, so that the number is bigger when the mouse is closer.
    dot.size = (110 - d) / size;
    dot.size = dot.size < 1 || !isHovering ? 1 : dot.size;

    dot.angle = getAngle(dot, mouse);

    return {
      x: (d > size ? size : d) * Math.cos(dot.angle * Math.PI / 180),
      y: (d > size ? size : d) * Math.sin(dot.angle * Math.PI / 180),
    };
  }

  function getAngle(obj1, obj2) {
    const dX = obj2.x - obj1.x;
    const dY = obj2.y - obj1.y;
    const angleDeg = Math.atan2(dY, dX) / Math.PI * 180;
    return angleDeg;
  }

  function getDistance(obj1, obj2) {
    const dx = obj1.x - obj2.x;
    const dy = obj1.y - obj2.y;
    return Math.sqrt(dx * dx + dy * dy);
  }

  /** *****************************************************************************
    Animation Setup
  ****************************************************************************** */
  (function animloop() {
    render();

    requestAnimationFrame(animloop);
  }());
}
