export default function initScrollReveal() {
  const $sections = document.querySelectorAll('[animate-up]');
  if ($sections.length === 0) return;

  let observer = null;

  const options = {
    rootMargin: '0px',
    threshold: 0,
  };

  const observerCallback = (entries) => {
    document.body.classList.add('has-scroll-reveal');

    entries.forEach((entry) => {
      const el = entry.target;
      const delay = el.getAttribute('animate-delay') || 50;

      if (entry.isIntersecting) {
        setTimeout(() => {
          entry.target.classList.add('isVisible');
        }, delay);

        if (el.hasAttribute('animate-up-children')) {
          const children = el.querySelectorAll('[animate-up-child]');
          let staggerDelay = delay;

          children.forEach((child, index) => {
            staggerDelay = index == 0 ? delay : parseInt(staggerDelay) + 175;

            setTimeout(() => {
              child.classList.add('isVisible');
            }, staggerDelay);
          });
        }
      }
    });
  };

  observer = new IntersectionObserver(observerCallback, options);
  $sections.forEach(($section) => {
    observer.observe($section);
  });
}
